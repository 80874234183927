
import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './AnalyticsDashboard.css';

function AnalyticsDashboard() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedType, setSelectedType] = useState("");

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

 


  const exportReport = () => {
    const formattedStartDate = startDate ? formatDate(startDate) : '';
    const formattedEndDate = endDate ? formatDate(endDate) : '';
    
    let endpoint = "";
    let filename = "";

    if (selectedType === "1") {
      endpoint = `/api/export-csv?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      filename = `overview_report_${formattedStartDate}_to_${formattedEndDate}.csv`;
    } else if (selectedType === "2") {
      endpoint = `/api/export-user-activity?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      filename = `user_activity_${formattedStartDate}_to_${formattedEndDate}.csv`;
    } else if (selectedType === "3") {
      endpoint = `/api/export-file-interactions?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      filename = `interaction_per_item_${formattedStartDate}_to_${formattedEndDate}.csv`;
  
    } else {
      console.error("Invalid report type selected.");
      return;
    }

    // fetch(`https://stgmeta.gdrfad.gov.ae${endpoint}`, {
    fetch(`${endpoint}`, {
      method: "GET",
    })
      .then((response) => response.blob()) 
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.error("Error exporting report:", error);
      });
  };

  const isExportEnabled = () => {
    if (selectedType === "4" || selectedType === "5") {
      return true;
    }
    return startDate && endDate;
  };

  return (
    <div className="dashboard-container d-flex justify-content-center align-items-center">
      <Container style={{
        width: "100%",
        background:" #dcdcdc",
        borderRadius: "25px",
        marginTop: "50px"
      }}>
        <Row className="justify-content-center">
          <Col md={6} className="text-center">
            <h1 className="display-4 mb-4" style={{textAlign:"center"}}>Analytics Dashboard</h1>

            <Form className="gray-border p-4">
              {/* Conditionally render Start Date and End Date based on selectedType */}
              {(selectedType === "1" || selectedType === "2" || selectedType === "3") && (
                <>
                  <Form.Group controlId="startDate" className="mb-3">
                    <Form.Label>Start Date:</Form.Label>
                    <DatePicker
                      className="form-control gray-border"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      placeholderText="Select Start Date"
                    />
                  </Form.Group>
                  <Form.Group controlId="endDate" className="mb-3">
                    <Form.Label>End Date:</Form.Label>
                    <DatePicker
                      className="form-control gray-border"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      placeholderText="Select End Date"
                    />
                  </Form.Group>
                </>
              )}

              {/* Dropdown to select report type */}
              <Form.Group controlId="reportType" className="mb-3">
                <Form.Label>Select Report Type:</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedType}
                  onChange={(e) => setSelectedType(e.target.value)}
                  className="gray-border"
                >
                  <option value="">Select Report Type</option>
                  <option value="1">Overview</option>
                  <option value="2">Users</option>
                  <option value="3">Interactions per User per Item</option>
                 
                </Form.Control>
              </Form.Group>

              {/* Export Button */}
              <div style={{ textAlign: "center" }}>
                {isExportEnabled() && (
                  <Button
                    className="gray-border export-btn"
                    variant="primary"
                    onClick={exportReport}
                  >
                    Export Report
                  </Button>
                )}
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AnalyticsDashboard;
