import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const WebViewer = ({ isOpen, onClose, url }) => {
  
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          height: '80%',
          overflowY: 'hidden',
          border: 'none',
          background: 'rgb(50 54 57)',
          padding: '0px',
        },
      }}
    >
      <button
        id="modalbtn"
        onClick={onClose}
        style={{
          float: 'right',
          background: '#8d1717',
          border: 'solid 1px #8d1717',
          width: '30px',
          height: '30px',
          color: '#ffffff',
          cursor: 'pointer',
        }}
      >
        X
      </button>
      <iframe
        src={url}
        width="100%"
        height="100%"
        style={{ border: 'none', overflowY: 'auto' }}
        title="Web Viewer"
      />
    </Modal>
  );
};

export default WebViewer;
