// import { LocalAudioTrack, RemoteAudioTrack } from "livekit-client";
// import { useEffect, useRef } from "react";

// function AudioComponent({ track }) {
//     const audioElement = useRef(null);

//     useEffect(() => {
//         if (audioElement.current) {
//             track.attach(audioElement.current);
//         }

//         return () => {
//             track.detach();
//         };
//     }, [track]);

//     return <audio ref={audioElement} id={track.sid} />;
// }

// export default AudioComponent;


// import React, { useEffect, useRef } from "react";

// const AudioComponent = ({ track, participantIdentity, isLocal }) => {
//     const audioRef = useRef();

//     useEffect(() => {
//         if (track && audioRef.current) {
//             track.attach(audioRef.current);
//             return () => {
//                 track.detach();
//             };
//         }
//     }, [track]);

//     return (
//         <div className="audio-container">
//             <audio ref={audioRef} autoPlay muted={isLocal} />
//             {!isLocal && <div className="participant-name">{participantIdentity}</div>}
//         </div>
//     );
// };

// export default AudioComponent;


import { useEffect, useRef } from "react";

function AudioComponent({ track }) {
  const audioElement = useRef(null);

  useEffect(() => {
    if (audioElement.current) {
      track.attach(audioElement.current);
    }

    return () => {
      track.detach();
    };
  }, [track]);

  return <audio ref={audioElement} id={track.sid} />;
}

export default AudioComponent;
