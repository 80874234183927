import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faMicrophoneSlash, faVideo, faVideoSlash, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Room, RoomEvent, createLocalTracks } from 'livekit-client';
import './App.css';
import { Dropdown } from 'react-bootstrap';
import VideoComponent from "./components/VideoComponent1";
import AudioComponent from "./components/AudioComponent1";
import {UNITY_URLS} from '../../utils/config';
import { useUnityContext } from "react-unity-webgl";

// const APPLICATION_SERVER_URL = "https://dolbyauth.holofair.io"; 
// const LIVEKIT_URL = "wss://mediastream.holofair.io/"; 

const APPLICATION_SERVER_URL = window.APP_CONFIG?.APPLICATION_SERVER_URL; 
const LIVEKIT_URL = window.APP_CONFIG?.LIVEKIT_URL; 


const VideoSession = ({start,update}) => {
  
  const [room, setRoom] = useState(null);
  const {addEventListener, removeEventListener} = useUnityContext(UNITY_URLS);
  const [localParticipant, setLocalParticipant] = useState(null);
  const [localTracks, setLocalTracks] = useState({ audioTrack: null, videoTrack: null });
  const [remoteParticipants, setRemoteParticipants] = useState([]);
  const [isAudioMuted, setIsAudioMuted] = useState(true);
  const [isVideoStopped, setIsVideoStopped] = useState(false);
  const [participantName, setParticipantName] = useState("Participant" + Math.floor(Math.random() * 100));
  const [roomName, setRoomName] = useState("TestRoom");
  const [audioEnabled, setAudioEnabled] = useState(false);
  const [videoEnabled, setVideoEnabled] = useState(false);
  const [showParticipantsList, setShowParticipantsList] = useState(true);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [timerIntervalId, setTimerIntervalId] = useState(null);
  const [localTrack, setLocalTrack] = useState(undefined);
  const [remoteTracks, setRemoteTracks] = useState([]);
  //const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);
  const [isLocalAudioEnabled, setIsLocalAudioEnabled] = useState(true);






  const leaveRoom = useCallback(async()=> {
    await room?.disconnect();
    update(false)
    setRoom(undefined);
    setLocalTrack(undefined);
    setIsAudioMuted(false)
    setAudioEnabled(false)
    setRemoteTracks([]);
    localStorage.setItem("join","")
  },[room, update])


  const joinRoom = useCallback(async() => {
    const room = new Room();
    setRoom(room);

    room.on(
      RoomEvent.TrackSubscribed,
      (_track, publication, participant) => {
        setRemoteTracks((prev) => [
          ...prev,
          { trackPublication: publication, participantIdentity: participant.identity }
        ]);
      }
    );

    room.on(RoomEvent.TrackUnsubscribed, (_track, publication) => {
      setRemoteTracks((prev) =>
        prev.filter((track) => track.trackPublication.trackSid !== publication.trackSid)
      );
    });



    room.on(RoomEvent.TrackMuted, (_track, participant) => {
      setRemoteTracks((prev) =>
        prev.map((track) =>
          track.participantIdentity === participant.identity
            ? { ...track, isAudioEnabled: false }
            : track
        )
      );
    });

    room.on(RoomEvent.TrackUnmuted, (_track, participant) => {
      setRemoteTracks((prev) =>
        prev.map((track) =>
          track.participantIdentity === participant.identity
            ? { ...track, isAudioEnabled: true }
            : track
        )
      );
    });




    try {
      const token = await getToken(roomName, participantName);
      console.log(token)
      await room.connect(LIVEKIT_URL, token);
      await room.localParticipant.enableCameraAndMicrophone();
      setLocalTrack(room.localParticipant.videoTrackPublications?.values().next().value.videoTrack);

      setTimeElapsed(0);
      if (timerIntervalId) clearInterval(timerIntervalId); 
      
      const intervalId = setInterval(() => {
        setTimeElapsed((prevTime) => prevTime + 1); 
      }, 1000); 
      
      setTimerIntervalId(intervalId);



    } catch (error) {
      console.log("There was an error connecting to the room:", error.message);
      await leaveRoom();
    }
  },[leaveRoom, participantName, roomName, timerIntervalId])

  useEffect(()=>{
    if(remoteTracks)
    console.log(remoteTracks)
  },[remoteTracks])




  useEffect(() =>{
    if(start === true && localStorage.getItem("join") === ""){
      localStorage.setItem("join","yes")
     joinRoom();
    }
 },[joinRoom, participantName, roomName, start])


 const leaveOVfunc = useCallback(()=>{
  console.log("Leaving now")
  leaveRoom();
  
},[leaveRoom])


useEffect(() =>{
  addEventListener("LeaveOpenVidu",leaveOVfunc);
  const handleBeforeUnload = () => leaveRoom();
  window.addEventListener('beforeunload', handleBeforeUnload);
  return () => {
    removeEventListener("LeaveOpenVidu",leaveOVfunc);
   window.removeEventListener('beforeunload', handleBeforeUnload);
   }
  
},[addEventListener, leaveOVfunc, leaveRoom, removeEventListener])


  async function getToken(roomName, participantName) {
    const response = await fetch(APPLICATION_SERVER_URL + "/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        roomName: roomName,
        participantName: participantName
      })
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(`Failed to get token: ${error.errorMessage}`);
    }

    const data = await response.json();
    return data.token;
  }









  

  // useEffect(() => {
  //   if (room) {
  //     room.on(RoomEvent.ParticipantConnected, (participant) => {
  //       setRemoteParticipants((prev) => [...prev, participant]);
  //     });

  //     room.on(RoomEvent.ParticipantDisconnected, (participant) => {
  //       setRemoteParticipants((prev) => prev.filter((p) => p.sid !== participant.sid));
  //     });

  //     room.on(RoomEvent.TrackSubscribed, (track, publication, participant) => {
  //       setRemoteParticipants((prev) =>
  //         prev.map((p) => (p.sid === participant.sid ? { ...p, [track.kind]: track } : p))
  //       );
  //     });

  //     room.on(RoomEvent.TrackUnsubscribed, (track, publication, participant) => {
  //       setRemoteParticipants((prev) =>
  //         prev.map((p) => {
  //           if (p.sid === participant.sid) {
  //             const updatedParticipant = { ...p };
  //             delete updatedParticipant[track.kind];
  //             return updatedParticipant;
  //           }
  //           return p;
  //         })
  //       );
  //     });

  //     if (room.participants) {
  //       const participantsArray = Array.from(room.participants.values());
  //       participantsArray.forEach((participant) => {
  //         setRemoteParticipants((prev) => [...prev, participant]);
  //       });
  //     }
  //   }
  // }, [room]);

  async function joinRoom2() {
    const newRoom = new Room();
    setRoom(newRoom);

    try {
      const token = await getToken(roomName, participantName);
      await newRoom.connect(LIVEKIT_URL, token);

      const tracks = await createLocalTracks({
        audio: true,
        video: true,
      });

      const audioTrack = tracks.find((track) => track.kind === "audio");
      const videoTrack = tracks.find((track) => track.kind === "video");

      if (audioTrack) {
        await newRoom.localParticipant.publishTrack(audioTrack);
        audioTrack.mute();
        setIsAudioMuted(true);
        setAudioEnabled(true);
      }

      if (videoTrack) {
        await newRoom.localParticipant.publishTrack(videoTrack);
        setIsVideoStopped(false);
        setVideoEnabled(true);
      }

      setLocalTracks({ audioTrack, videoTrack });
      setLocalParticipant(newRoom.localParticipant);

      setTimeElapsed(0);
      if (timerIntervalId) clearInterval(timerIntervalId); 
      
      const intervalId = setInterval(() => {
        setTimeElapsed((prevTime) => prevTime + 1); 
      }, 1000); // 1000 milliseconds
      
      setTimerIntervalId(intervalId);
    } catch (error) {
      await leaveRoom2();
    }
  }

  async function leaveRoom2() {
    await room?.disconnect();
    setRoom(null);
    setLocalParticipant(null);
    setLocalTracks({ audioTrack: null, videoTrack: null });
    setRemoteParticipants([]);
  }

  async function getToken2(roomName, participantName) {
    const response = await fetch(`${APPLICATION_SERVER_URL}/token`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ roomName, participantName }),
    });

    const data = await response.json();
    return data.token;
  }

  function toggleAudio2() {
    if (localTracks.audioTrack) {
      if (isAudioMuted) {
        localTracks.audioTrack.unmute();
        setIsAudioMuted(false);
        console.log("Audio unmuted");
      } else {
        localTracks.audioTrack.mute();
        setIsAudioMuted(true);
        console.log("Audio muted");
      }
      setAudioEnabled(!audioEnabled);
    }
  }
  

  function toggleVideo2() {
    console.log("toggleVideo:")
    if (localTracks.videoTrack) {
      if (isVideoStopped) {
        localTracks.videoTrack.unmute();
        setIsVideoStopped(false);
        console.log("Video unmuted");
      } else {
        localTracks.videoTrack.mute();
        setIsVideoStopped(true);
        console.log("Video unmuted");
      }
      setVideoEnabled(!videoEnabled);
    }
  }



  const toggleAudio = () => {
    if (room && room.localParticipant) {
      if (isLocalAudioEnabled) {
        room.localParticipant.setMicrophoneEnabled(false);
      } else {
        room.localParticipant.setMicrophoneEnabled(true);
      }
      setIsLocalAudioEnabled(!isLocalAudioEnabled);
    }
  };


  const toggleVideo = () => {
    if (room && room.localParticipant) {
      if (isVideoEnabled) {
        room.localParticipant.setCameraEnabled(false);
      } else {
        room.localParticipant.setCameraEnabled(true);
      }
      setIsVideoEnabled(!isVideoEnabled);
    }
  };


  const formatTimeElapsed = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
  
    const paddedHours = hours.toString().padStart(2, '0');
    const paddedMinutes = minutes.toString().padStart(2, '0');
    const paddedSeconds = seconds.toString().padStart(2, '0');
  
    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
  };


  // const getParticipantName = (participant) => {
  //   return participant.participantIdentity ? participant.participantIdentity : "Unknown";
  // };

  function getParticipantName(participant) {
    return participant.identity || participant || "Unnamed Participant";
  }
  

  return (
    <div className="container" style={{
      position: "fixed",
      bottom: "150px",
      left: "50%",
      transform: "translateX(-50%)"
    }}>
      {!room && !localParticipant ? (
        // <div id="join">
        //   <div id="join-dialog" className="jumbotron vertical-center">
        //     <h1>No Session yet</h1>
        //     <button className="btn btn-primary" onClick={joinRoom}>Join Room</button>
        //   </div>
        // </div>
        <></>
      ) : (
        <div id="session" style={{ position: "", width: "100%", height: "100%" }}>
          {/* <div id="session-header" style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
            <button className={`btn btn-${audioEnabled ? 'success' : 'danger'}`} onClick={toggleAudio}>
              {audioEnabled ? 'Mute' : 'Unmute'}
            </button>
            <button className={`btn btn-${videoEnabled ? 'danger' : 'success'}`} onClick={toggleVideo}>
              {videoEnabled ? 'Disable Video' : 'Enable Video'}
            </button>
            <button className="btn btn-warning" onClick={() => console.log('Share Screen')}>Share Screen</button>
            <button className="btn btn-danger" onClick={leaveRoom}>Leave Session</button>
          </div> */}

                {/* <div id="video-container" className="grid-container" style={{ visibility: showParticipantsList ? 'visible' : 'hidden' }}>
                    {localParticipant && localTracks.videoTrack && (
                        <div className="stream-container">
                            <video autoPlay ref={(ref) => localTracks.videoTrack?.attach(ref)} className="video-element" />
                            <div className="participant-name">{localParticipant.identity || "You"}</div>
                            <div className="audio-icon">
                                {audioEnabled ? <FontAwesomeIcon icon={faMicrophone} /> : <FontAwesomeIcon icon={faMicrophoneSlash} />}
                            </div>
                        </div>
                    )}
                    {remoteParticipants.map((participant) => (
                        <div key={participant.sid} className="stream-container">
                            <video autoPlay ref={(ref) => participant?.video?.attach(ref)} className="video-element" />
                            <div className="participant-name">{participant.identity}</div>
                            <div className="audio-icon">
                                {participant?.audio?.isMuted() ? <FontAwesomeIcon icon={faMicrophoneSlash} /> : <FontAwesomeIcon icon={faMicrophone} />}
                            </div>
                        </div>
                    ))}
                </div> */}


<div id="video-container" className="grid-container" style={{ visibility: showParticipantsList ? 'visible' : 'hidden' }}>
            {/* Local participant video */}
            {/* {localParticipant && localTracks.videoTrack && (
                <div className="stream-container">
                    <video autoPlay ref={(ref) => localTracks.videoTrack?.attach(ref)} className="video-element" />
                   
                    <div className="participant-name">{localParticipant.identity || "You"}</div>
                    
                    <div className="audio-icon">
                        {audioEnabled ? (
                            <FontAwesomeIcon icon={faMicrophone} />
                        ) : (
                            <FontAwesomeIcon icon={faMicrophoneSlash} />
                        )}
                    </div>
                </div>
            )} */}
            {/* Remote participants videos */}

                 {/* {remoteTracks.map((participant) => {
                        // Safely check if audioTracks exist
                        const audioTrackPublication = participant.audioTracks && Array.from(participant.audioTracks.values())[0];
                        const audioTrack = audioTrackPublication ? audioTrackPublication.track : null;

                        return (
                            <div key={participant.sid} className="stream-container">
                                <video autoPlay ref={(ref) => participant?.video?.attach(ref)} className="video-element" />
                              
                                <div className="participant-name">{participant.identity}</div>
                               
                                <div className="audio-icon">
                                    {audioTrack && !audioTrack.isMuted() ? (
                                        <FontAwesomeIcon icon={faMicrophone} />
                                    ) : (
                                        <FontAwesomeIcon icon={faMicrophoneSlash} />
                                    )}
                                </div>
                            </div>
                        );
                    })} */}





              {localTrack && (
                <div className="stream-container" key="local" >
                    <VideoComponent track={localTrack} participantIdentity={participantName} local={true} />

                    {/* <div className="participant-name">{participantName || "You"}</div> */}
                    
                    <div className="audio-icon">
                        {isLocalAudioEnabled ? (
                            <FontAwesomeIcon icon={faMicrophone} />
                        ) : (
                            <FontAwesomeIcon icon={faMicrophoneSlash} />
                        )}
                    </div>
                </div>
                
              )}
       
              {remoteTracks.map((remoteTrack) => 
                remoteTrack.trackPublication.kind === "video" ? (
                  <div className="stream-container" key={remoteTrack.trackPublication.trackSid} >
                  <VideoComponent
                    key={remoteTrack.trackPublication.trackSid}
                    track={remoteTrack.trackPublication.videoTrack}
                    participantIdentity={remoteTrack.participantIdentity}
                  />

                  {/* <div className="participant-name">{remoteTrack.identity || "You"}</div> */}
                    <div className="audio-icon">
                        {!remoteTrack.isAudioEnabled  ? (
                            <FontAwesomeIcon icon={faMicrophone} />
                        ) : (
                            <FontAwesomeIcon icon={faMicrophoneSlash} />
                        )}
                    </div>
                  </div>
                ) : (
                 
                  <AudioComponent
                    key={remoteTrack.trackPublication.trackSid}
                    track={remoteTrack.trackPublication.audioTrack}
                    isAudioEnabled={remoteTrack.isAudioEnabled} 
                  />
                 
                )
              )}
        </div>
                        

          <div style={{ width: "900px", bottom: "", position: "absolute", left: "50%", transform: "translate(-48%, 5%)" }}>
            <div className='toolbar2'>
              <button onClick={toggleVideo} title="Enable/Disable Video" className='ops'>
                <FontAwesomeIcon icon={isVideoEnabled ? faVideo : faVideoSlash} />
              </button>
              <button onClick={toggleAudio} title="Mute/Unmute Audio" className='ops'>
                <FontAwesomeIcon icon={isLocalAudioEnabled ? faMicrophone : faMicrophoneSlash} />
              </button>
              <button onClick={() => setShowParticipantsList(!showParticipantsList)} className='ops' title="Participants">
                <FontAwesomeIcon icon={faUsers} />
              </button>
              <button onClick={leaveRoom} title="End call" className='redbutton'>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="32" height="32" rx="16" />
                <path fillRule="evenodd" clipRule="evenodd" d="M25.7785 14.3781C26.2087 14.8083 26.4503 15.3917 26.4503 16C26.4503 16.6083 26.2087 17.1917 25.7785 17.6219L25.0368 18.3636C24.5718 18.8286 23.8496 18.9172 23.2857 18.5793L20.2971 16.7855C20.0552 16.6404 19.8614 16.4271 19.7401 16.1723C19.6189 15.9176 19.5755 15.6327 19.6154 15.3534L19.79 14.13C19.8084 14.0024 19.744 13.9067 19.6678 13.8716C18.5329 13.3469 17.2975 13.0752 16.0472 13.0752C14.7969 13.0752 13.5616 13.3469 12.4267 13.8716C12.3504 13.9067 12.2866 14.0018 12.3045 14.13L12.4791 15.3534C12.519 15.6327 12.4756 15.9176 12.3543 16.1723C12.2331 16.4271 12.0393 16.6404 11.7974 16.7855L8.8088 18.5793C8.24492 18.9172 7.52265 18.8286 7.05717 18.3631L6.31597 17.6219C5.88582 17.1917 5.64417 16.6083 5.64416 16C5.64417 15.3917 5.88582 14.8083 6.31597 14.3781L7.53238 13.1617C12.2347 8.45935 19.8597 8.45935 24.5621 13.1617L25.7785 14.3781Z" fill="white"/>
              </svg>
              </button>
            </div>






            <div className="toolbar" style={{display:""}}>
        
          

        <div  style={{width:"57%"}}>
        <div className="d-flex align-items-center" style={{ border:"2px solid #239198",background: '#222',marginLeft:"11px", color: 'white', width:"100%",fontSize:"16px", padding: '7px', borderRadius: '50px' }}>
          <span className="mr-2" style={{ color: '#ffffff',paddingLeft:"5px",textWrap:"nowrap",width:"70px"}}>Public call</span>
          <div className="timer">{formatTimeElapsed(timeElapsed)}</div>
        </div>
        

        </div>

             {/* <Dropdown style={{ marginLeft: "21px" }} drop="up">
                <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ background: "#222" }}>
                  {remoteParticipants.length + 1} users are on the call
                </Dropdown.Toggle>
                <Dropdown.Menu>
                
                  {localParticipant && (
                    <Dropdown.Item href="#/action-1">
                      {getParticipantName(localParticipant)} (You)
                    </Dropdown.Item>
                  )}
                
                  {remoteTracks.map((participant, i) => (
                    <Dropdown.Item href={`#/action-${i + 2}`} key={participant.sid}>
                      {getParticipantName(participant)}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown> */}




            <Dropdown style={{ marginLeft: "21px" }} drop="up">
              <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ background: "#222" }}>
                {/* {remoteTracks.length + 1 - 1 } users are on the call */}
                {new Set([...(room?.localParticipant ? [room.localParticipant.identity] : []), ...remoteTracks.map((remoteTrack) => remoteTrack.participantIdentity)]).size} 
                {new Set([...(room?.localParticipant ? [room.localParticipant.identity] : []), ...remoteTracks.map((remoteTrack) => remoteTrack.participantIdentity)]).size === 1 ? ' user is ' : ' users are '} 
                 on the call
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {/* Local participant */}
                {room?.localParticipant && (
                  <Dropdown.Item href="#/action-local">
                    {getParticipantName(room.localParticipant)} (You)
                  </Dropdown.Item>
                )}

                {/* Remote participants */}
                {/* {remoteTracks.map((remoteTrack, index) => (
                  <Dropdown.Item href={`#/action-${index + 1}`} key={remoteTrack.trackPublication.trackSid}>
                    {getParticipantName(remoteTrack.participantIdentity)}
                  </Dropdown.Item>
                ))} */}

              {[...new Set(remoteTracks.map((remoteTrack) => remoteTrack.participantIdentity))].map((participantIdentity, index) => (
                    <Dropdown.Item href={`#/action-${index + 1}`} key={participantIdentity}>
                      {getParticipantName(participantIdentity)}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>



      </div>

          </div>
        </div>
      )}
    </div>
  );
};

export default VideoSession;
